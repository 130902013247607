// title: About Lucky Paper
// frameCardName: Celestial Ancient
// frameCardSet: DIS
// lastUpdatedDate: 2023-03-11

import * as styles from './about.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props {
  pageContext: any
}

const AboutPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#0562F3"
      includeNewsletterSignup
    >
      <article className={styles.container}>
        <h1 className={styles.heading}>
          <Typography.SecondaryHeading>
            Lucky Paper is a love letter to Magic: the Gathering.
          </Typography.SecondaryHeading>
        </h1>
        <Typography.Paragraph>
          We use Magic as a framework to explore subjects like game theory,
          statistical analysis, epistemology, data visualization, and community.
          Our primary focus is &ldquo;sandbox&rdquo; formats like{' '}
          <Link to="/what-is-a-cube/">Cube</Link> and Battle Box. Free of
          banlists, rotation, netdecking, and metagaming, these environments
          afford us the freedom to pursue the aspects of the game that inspire
          us most.
        </Typography.Paragraph>
        <div className={styles.byline}>
          <Typography.Paragraph>
            <a href="https://bsky.app/profile/andy.luckypaper.co">
              Andy Mangold
            </a>
            , Jett Crowdis,{' '}
            <a href="https://bsky.app/profile/anthony.luckypaper.co">
              Anthony Mattox
            </a>
            , &amp;{' '}
            <a href="https://bsky.app/profile/parker.luckypaper.co">
              Parker LaMascus
            </a>
            <br />
            <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a>
          </Typography.Paragraph>
        </div>

        <Typography.SecondaryDivider />

        <div className={styles.links}>
          <Typography.Paragraph>
            Find Lucky Paper on{' '}
            <a href="https://www.twitch.tv/luckypapermtg">Twitch</a> and{' '}
            <a href="https://www.youtube.com/channel/UCx9a677zDh8t5jvOHQuBsjA">
              YouTube
            </a>
            .
          </Typography.Paragraph>
        </div>

        <Typography.Divider />
      </article>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'About Lucky Paper',
        description:
          'We use Magic: the Gathering as a framework to explore subjects like game theory, statistical analysis, epistemology, data visualization, and community. Our primary focus is "sandbox" formats like Cube and Battle Box. Free of banlists, rotation, netdecking, and metagaming, these environments afford us the freedom to pursue the aspects of the game that inspire us most.',
      }}
    />
  )
}

export default AboutPage
